import { useEffect, useState } from 'react';
import styled from 'styled-components';

import ExploreMasonary from './explore-masonary';
import ExploreFilters, { FilterValues } from './explore-filters';
import Analytics from '../../lib/user-analytics';

const ExploreV2 = () => {
    const [filterValues, setFilterValues] = useState<FilterValues | null>(null);

    useEffect(() => {
        Analytics.Event('Explore V2', 'All filter values applied', JSON.stringify(filterValues));
    }, [filterValues]);

    // causes infinite renders otherwise
    const handleOnFilterChange = (v) => {
        if (JSON.stringify(filterValues) !== JSON.stringify(v)) setFilterValues(v);
    };

    return (
        <ExploreContainer>
            <ExploreFilters onFilter={(v) => handleOnFilterChange(v)} isVersion2={true} />
            {filterValues && (
                <ExploreMasonary
                    searchTerm={filterValues.keywords}
                    category={filterValues.category}
                    aoi={filterValues.aoi}
                    orderBy={filterValues.orderBy}
                    version={2}
                />
            )}

            <ExploreMobileBottomFill />
        </ExploreContainer>
    );
};

const ExploreContainer = styled.div`
    background-color: #191a1a;
    width: 100%;
    height: 100vh;
    padding-top: 80px;

    @media only screen and (max-width: 600px) {
        padding-top: 55px;
        min-height: calc(100vh - 144px);
    }
`;

const ExploreMobileBottomFill = styled.div`
    display: none;

    @media only screen and (max-width: 600px) {
        display: block;
        width: 100%;
        position: fixed;
        bottom: 0;
        top: calc(100vh - 60px);
        background: black;
    }
`;

export default ExploreV2;
