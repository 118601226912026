import { useInfiniteLoader, usePositioner, useResizeObserver } from 'masonic';
import { useScroller, useSize } from 'mini-virtual-list';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import ApiListings, { GetListingsParams } from '../../../../../../api/api-listings';
import { AutocompleteDTO } from '../../../../../../api/model';
import GeoUtil from '../../../../../../lib/geo-util';
import {
    selectSelectedCategory,
    selectSelectedContinent,
    selectSelectedSearchTerm,
} from '../../../../../../store/Map/MapSelection/selectors';
import SearchResultsMasonary, { SelectableListing } from './search-results-masonary';
import { SegmentContainer, SegmentTitle } from './search-container';
import SearchResultsBreadcrumbs from './search-results-breadcrumbs';
import Analytics from '../../../../../../lib/user-analytics';
import SearchResultsEmptyView from './search-results-empty-view';
import SearchResultsContinentFilter from './search-results-continent-filter';
import SearchResultsNoFilter from './search-results-no-filter';
import SearchResultsAutocomplete from './search-results-autocomplete';
import SearchResultsCategoryFilter from './search-results-category-filter';
import SoarHelper from '../../../../../../lib/soar-helper';
import { PulseLoader } from '../../../../../Shared/pulse-loader';
// import Select from 'react-select/dist/declarations/src/Select';

const LIMIT = 40;
const THRESHOLD = 40;

interface SearchResultsProps {
    handleSelectListing: (id: number, listingData?: SelectableListing) => void;
    handleSelectAutocomplete: (location: AutocompleteDTO) => void;
    autoCompleteResults: AutocompleteDTO[];
    disableFilters?: boolean;
}

const SearchResults = (props: SearchResultsProps) => {
    const containerRef = useRef(null);
    const { width, height } = useSize(containerRef);
    const { scrollTop, isScrolling } = useScroller(containerRef);
    const selectedCategory = useSelector(selectSelectedCategory);
    const selectedContinent = useSelector(selectSelectedContinent);
    const selectedSearchTerm = useSelector(selectSelectedSearchTerm);

    const [coordinateAutocomplete, setCoordinateAutocomplete] = useState<AutocompleteDTO | undefined>(undefined);
    const [fetchStartIndex, setFetchStartIndex] = useState(0);
    const [fetchStopIndex, setFetchStopIndex] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [items, setItems] = useState<SelectableListing[]>([]);
    const [fetchTriggered, setFetchTriggered] = useState(false);

    const positioner = usePositioner(
        {
            width: width,
            columnGutter: 16,
            columnCount: 4,
        },
        [items.length]
    );

    const resizeObserver = useResizeObserver(positioner);

    const fetchListings = (limit: number, offset: number, cancelToken?: boolean) => {
        const searchParams: GetListingsParams = {
            aoi:
                selectedContinent && selectedContinent.continent
                    ? GeoUtil.latLngBoundsToWKT(selectedContinent.latlngBounds)
                    : undefined,
            category: selectedCategory ? selectedCategory.value : undefined,
            keywords: selectedSearchTerm ? selectedSearchTerm.trim() : undefined,
            offset: offset,
            limit: limit,
            version: 4,
        };

        // Only apply cancel token if we are loading more
        if (cancelToken) {
            ApiListings.cancelGetListings('Cancel search results changed');
        }

        setIsLoading(true);
        ApiListings.getListings(searchParams)
            .then((res) => {
                const results = res.map((t) => {
                    const listing: SelectableListing = {
                        id: t.id,
                        authorName: t.userName,
                        title: t.title,
                        handleSelectListing: () => props.handleSelectListing(t.id),
                        createdAt: t.createdAt * 1000,
                        popularity: t.totalLikes,
                        listingType: t.listingType,
                        categories: t.categories,
                    };
                    return listing;
                });

                const allItems = [...items, ...results];
                const uniqueItems = allItems.filter((item, index) => {
                    return allItems.findIndex((i) => i.id.toString() === item.id.toString()) === index;
                });
                setItems(uniqueItems);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        setItems([]);
        setFetchTriggered(true);
    }, [selectedCategory, selectedContinent, selectedSearchTerm]);

    useEffect(() => {
        if (fetchTriggered) {
            setFetchStartIndex(0);
            setFetchStopIndex(0);
            fetchListings(LIMIT, 0, true);
            requestAnimationFrame(() => {
                setFetchTriggered(false);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchTriggered]);

    useEffect(() => {
        if (selectedSearchTerm) {
            const coordinate = GeoUtil.getCoordinateFromSearchTerm(selectedSearchTerm);
            if (coordinate) {
                const autocomplete: AutocompleteDTO = {
                    title: selectedSearchTerm,
                    type: 'COORDINATE',
                    latlng: coordinate,
                };
                setCoordinateAutocomplete(autocomplete);
            } else {
                setCoordinateAutocomplete(undefined);
            }
        }
    }, [selectedSearchTerm]);

    // The Masonic function for loading more tends to spam requests while populating
    // To workaround, we only trigger the next load when the indices update
    useEffect(() => {
        if (fetchStartIndex === 0 && fetchStopIndex === 0) {
            return;
        }
        fetchListings(LIMIT, items.length, true);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchStartIndex, fetchStopIndex]);

    const maybeLoadMore = useInfiniteLoader(
        async (startIndex, stopIndex, _) => {
            setFetchStartIndex(startIndex);
            setFetchStopIndex(stopIndex);
        },
        {
            threshold: THRESHOLD,
            minimumBatchSize: LIMIT,
            isItemLoaded: (index, items) => !!items[index],
        }
    );

    return (
        <React.Fragment>
            {!props.disableFilters ? (
                <React.Fragment>
                    <SearchResultsAutocomplete
                        autocomplete={props.autoCompleteResults}
                        autocompleteCoordinate={coordinateAutocomplete}
                        handleSelectAutocomplete={props.handleSelectAutocomplete}
                    />

                    <SearchResultsCategoryFilter />

                    {SoarHelper.isSoar() ? <SearchResultsContinentFilter /> : <Spacer />}

                    <SearchResultsNoFilter />
                </React.Fragment>
            ) : null}

            <Container>
                <SegmentTitle>
                    Browsing results for <SearchResultsBreadcrumbs />
                </SegmentTitle>

                <MasonryContainer>
                    <Masonic ref={containerRef}>
                        {items.length > 0 ? (
                            <SearchResultsMasonary
                                positioner={positioner}
                                resizeObserver={resizeObserver}
                                items={items}
                                height={height}
                                scrollTop={scrollTop}
                                isScrolling={isScrolling}
                                maybeLoadMore={maybeLoadMore}
                                handleSelectListing={(id, data) => {
                                    Analytics.Event('Search Bar', 'Clicked Map From Search Results', id);
                                    props.handleSelectListing(id, data);
                                }}
                            />
                        ) : !isLoading && !fetchTriggered ? (
                            <SearchResultsEmptyView
                                isLoading={isLoading}
                                continent={selectedContinent}
                                term={selectedSearchTerm}
                            />
                        ) : null}
                        {isLoading && <PulseLoader />}
                    </Masonic>
                </MasonryContainer>
            </Container>
        </React.Fragment>
    );
};

export default SearchResults;

const Container = styled(SegmentContainer)`
    margin: 0;
    padding: 0px 32px;
    background: none;
`;

const MasonryContainer = styled.main`
    display: flex;
    flex-direction: column;
    max-height: 75vh;
    width: 100%;
    overflow: hidden;
`;

const Masonic = styled.div`
    height: calc(75vh - 155px);
    margin: 2px;
    overflow-x: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar-track {
        margin: 3px 0;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        background-color: transparent;
    }

    &::-webkit-scrollbar {
        margin: 3px 0;
        width: 4px;
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        margin: 3px 0;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        background-color: #eed926;
        border-radius: 4px;
    }
`;

const Spacer = styled.div`
    height: 15px;
`;
