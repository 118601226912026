import { useEffect, useState } from 'react';
import { AnalyticsNote, ListingDTO } from '../../../../api/model';
import SideDrawerInfiniteScroll from '../Shared/side-drawer-infinite-scroll';

interface MapSearchDrawAOIResultsProps {
    listings: ListingDTO[] | undefined;
    listingsCount: number;
    isLoading: boolean;
    onLoadMore: () => void;
}

const MapSearchDrawAOIResults = ({ listings, listingsCount, isLoading, onLoadMore }: MapSearchDrawAOIResultsProps) => {
    // For animation purposes
    const [exitingListings, setExitingListings] = useState<Set<number>>(new Set());
    const [currentScrollPosition, setCurrentScrollPosition] = useState<number>(0);

    useEffect(() => {
        if (listings) {
            const incomingListingsMap = new Map<number, ListingDTO>(listings.map((listing) => [listing.id, listing]));
            const exitingListingsSet = new Set(
                listings.map((listing) => listing.id).filter((id) => !incomingListingsMap.has(id))
            );

            setExitingListings(exitingListingsSet);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listings]);

    return (
        <SideDrawerInfiniteScroll
            type="MapSearch"
            listings={listings}
            exitingListings={exitingListings}
            listingsCount={listingsCount}
            isLoading={isLoading}
            onLoadMore={onLoadMore}
            heightPadding={'135px'}
            onScrollTo={(scrollY) => {
                setCurrentScrollPosition(scrollY);
            }}
            initialScrollPosition={currentScrollPosition}
            analyticNote={AnalyticsNote.BROWSE_PAGE}
        />
    );
};

export default MapSearchDrawAOIResults;
