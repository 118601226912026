import React, { useEffect } from 'react';
import styled from 'styled-components';
import ApiListings from '../../api/api-listings';
import { ListingDTO } from '../../api/model';
import BackgroundGradientAnimation from '../LandingPage/Animations/background-gradient-animation';
import SearchResults from './search-results';

const HomePage = () => {
    const [searchTerm, setSearchTerm] = React.useState<string>('');
    const [searchResults, setSearchResults] = React.useState<ListingDTO[]>([]);

    const [searchFilter, setSearchFilter] = React.useState<string>('Default');

    const handleScroll = () => {
        const position = window.pageYOffset;
        const navbar = document.getElementsByClassName('navbar')[0] as HTMLElement;
        if (position <= 50) {
            navbar.style.backgroundColor = 'transparent';
        } else {
            navbar.style.backgroundColor = 'rgba(0,0,0,0.8)';
        }
    };

    useEffect(() => {
        if (searchTerm.length > 2) {
            setSearchResults([]);
            const orderBy = searchFilter === 'Default' ? undefined : searchFilter.toLocaleLowerCase();

            ApiListings.searchListings(20, 0, searchTerm, undefined, undefined, orderBy).then(
                (listings: ListingDTO[]) => {
                    setSearchResults(listings);
                }
            );
        }
    }, [searchTerm, searchFilter]);

    useEffect(() => {
        const navbar = document.getElementsByClassName('navbar')[0] as HTMLElement;
        navbar.style.backgroundColor = 'transparent';

        window.addEventListener('scroll', handleScroll, { passive: true });
        return () => {
            const navbar = document.getElementsByClassName('navbar')[0] as HTMLElement;
            navbar.style.backgroundColor = 'rgba(0,0,0,0.8)';
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <React.Fragment>
            <BackgroundGradientAnimation />
            <HeroContainer>
                <Logo src="/assets/logos/soar_logo_large.png" />
            </HeroContainer>
            <SearchBarContainer>
                <SearchIconContainer>
                    <SearchIcon className="fa fa-search" />
                </SearchIconContainer>
                <SearchInputContainer>
                    <SearchInput
                        rows={1}
                        title="Search"
                        value={searchTerm}
                        aria-label="Search"
                        spellCheck={false}
                        onChange={(e) => {
                            setSearchTerm(e.target.value);
                        }}
                    />
                </SearchInputContainer>

                <SearchFilterContainer>
                    <Select
                        defaultValue="Default"
                        onChange={(e) => {
                            setSearchFilter(e.target.value);
                        }}
                    >
                        <option value="Default">Default</option>
                        <option value="Views">Views</option>
                        <option value="Comments">Comments</option>
                        <option value="Likes">Likes</option>
                        <option value="Created">Created</option>
                        <option value="Area">Area</option>
                    </Select>
                </SearchFilterContainer>
            </SearchBarContainer>

            {searchResults.length > 0 && <SearchResults listings={searchResults} />}
        </React.Fragment>
    );
};

export default HomePage;

const HeroContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 290px;
`;

const Logo = styled.img`
    width: 350px;
    margin-top: auto;
`;

const SearchBarContainer = styled.div`
    display: flex;
    z-index: 3;
    min-height: 44px;
    background: #202124;
    border: 1px solid #5f6368;
    box-shadow: none;
    border-radius: 24px;
    margin: 0 auto;
    width: 638px;
    width: auto;
    max-width: 584px;
    margin-top: 20px;
`;

const SearchIconContainer = styled.div`
    display: flex;
    align-items: center;
    padding-left: 13px;
    padding-right: 8px;
    margin-top: 0px;
    height: 46px;
`;

const SearchIcon = styled.i`
    color: white;
`;

const SearchInputContainer = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    margin: 9px 0 0 16px;
`;

const SearchInput = styled.textarea`
    line-height: 22px;
    margin-bottom: 6px;
    overflow-x: hidden;
    resize: none;
    background-color: transparent;
    border: none;
    margin: 0;
    margin-bottom: 0px;
    padding: 0;
    color: #e8eaed;
    word-wrap: break-word;
    outline: none;
    display: flex;
    font-size: 22px;
    flex: 100%;
`;

const SearchFilterContainer = styled.div`
    display: flex;
`;

const Select = styled.select`
    border-radius: 4px;
    border: none;
    border-left: 1px solid rgba(255, 255, 255, 0.1);
    background-color: transparent !important;
    font-size: 1rem;
    outline: none;
    color: rgba(255, 255, 255, 0.6);
    width: 100px;

    padding: 5px;
    margin-right: 8px;
    cursor: pointer;

    option {
        color: rgba(255, 255, 255, 0.6);
        background-color: rgba(0, 0, 0, 0.8);
    }

    :hover {
        border: 1px solid rgba(238 227 8 / 0.6) !important;
        border-color: rgba(238 227 8 / 0.6) !important;
        box-shadow: 0 0 0 0.2rem rgba(238 227 8 / 0.1) !important;
    }
`;
