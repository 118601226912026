import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import styled, { keyframes } from 'styled-components';
import Analytics from '../../../../lib/user-analytics';

interface MapNestedSideDrawerProps {
    controlText: string;
    controlComponent?: React.ReactNode;
    controlIcon?: React.ReactNode;
    children: React.ReactNode;
    defaultOpen?: boolean;
    closeDrawer?: boolean;
    left?: number;
    width?: string;
    height?: string;
    onExpandedChange?: (expanded: boolean) => void;
}

const SideDrawerNested = ({
    controlText,
    controlComponent,
    controlIcon,
    children,
    closeDrawer,
    left,
    width,
    height,
    defaultOpen,
    onExpandedChange,
}: MapNestedSideDrawerProps) => {
    const [showExpanded, setShowExpanded] = useState(defaultOpen ? defaultOpen : false);

    useEffect(() => {
        if (closeDrawer) {
            setShowExpanded(false);
        }
    }, [closeDrawer]);

    useEffect(() => {
        if (defaultOpen) {
            setShowExpanded(true);
        }
    }, [defaultOpen]);

    useEffect(() => {
        if (onExpandedChange && showExpanded) {
            onExpandedChange(true);
        }

        return () => {
            if (onExpandedChange) {
                onExpandedChange(false);
            }
        };
    }, [showExpanded, onExpandedChange]);

    const handleControlClick = (e: React.MouseEvent, text: string) => {
        e.preventDefault();
        e.stopPropagation();
        setShowExpanded(!showExpanded);
        Analytics.Event('Side Drawer', `Clicked Nested Draw ${text}`);
    };

    return (
        <NestedDrawerContainer>
            <React.Fragment>
                {controlComponent && (
                    <DrawComponent
                        className="nested-draw-component"
                        title={controlText}
                        onClick={(e) => handleControlClick(e, controlText)}
                    >
                        {controlComponent}
                    </DrawComponent>
                )}
                {controlIcon && (
                    <DrawIcon
                        className="nested-draw-icon"
                        title={controlText}
                        onClick={(e) => handleControlClick(e, controlText)}
                    >
                        {controlIcon}
                    </DrawIcon>
                )}
                {controlText && !controlComponent && !controlIcon && (
                    <DrawFilter className="nested-draw-text-filter" onClick={(e) => handleControlClick(e, controlText)}>
                        <React.Fragment>
                            <span>{controlText}</span>
                            <DrawFilterArrow open={showExpanded} className="fa fa-chevron-left" />
                        </React.Fragment>
                    </DrawFilter>
                )}
            </React.Fragment>
            {showExpanded && (
                <React.Fragment>
                    {ReactDOM.createPortal(
                        <ExpandedNestedDrawerContent
                            onClose={() => {
                                setShowExpanded(false);
                                Analytics.Event('Side Drawer', `Closed Nested Draw ${controlText}`);
                            }}
                            showExpanded={showExpanded}
                            left={left}
                            width={width}
                            height={height}
                        >
                            {children}
                        </ExpandedNestedDrawerContent>,
                        document.body
                    )}
                </React.Fragment>
            )}
        </NestedDrawerContainer>
    );
};

export default SideDrawerNested;

interface ExpandedNestedDrawerContentProps {
    onClose: () => void;
    showExpanded: boolean;
    children: React.ReactNode;
    left?: number;
    width?: string;
    height?: string;
}

const ExpandedNestedDrawerContent = ({
    onClose,
    showExpanded,
    children,
    left,
    width,
    height,
}: ExpandedNestedDrawerContentProps) => {
    return (
        <ExpandedNestedDrawerContainer expanded={showExpanded} left={left} width={width} height={height}>
            <CloseIcon onClick={onClose} className="fa fa-close" expanded={showExpanded} />
            {showExpanded && <SideDrawerFadeInContent expanded={showExpanded}>{children}</SideDrawerFadeInContent>}
        </ExpandedNestedDrawerContainer>
    );
};

const NestedDrawerContainer = styled.div`
    background: none;
    padding: 0px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    white-space: nowrap;
`;

const DrawComponent = styled.div`
    width: 100%;
`;

export const DrawIcon = styled.i`
    color: white;
    font-size: 1.2rem;
    padding: 5px;
    cursor: pointer;
    width: min-content;
    margin-left: 5px;
    align-content: center;

    &:hover {
        color: ${(props) => props.theme.color.yellow};
    }
`;

const DrawFilter = styled.div`
    color: white;
    font-size: 1.2rem;
    padding: 5px 0px;
    cursor: pointer;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
        color: inherit;
    }
`;

const DrawFilterArrow = styled.i<{ open: boolean }>`
    display: inline-block;
    margin-right: 13px;
    transition: transform 0.3s ease;
    transform: rotate(${(props) => (props.open ? '180deg' : '0deg')});
`;

const expand = keyframes`
  from {
    width: 0px;
  }
  to {
    width: 300px;
  }
`;

const collapse = keyframes`
  from {
    width: 300px;
  }
  to {
    width: 0px;
  }
`;

const ExpandedNestedDrawerContainer = styled.div<{ expanded: boolean; left?: number; width?: string; height?: string }>`
    position: absolute;
    top: 70px;
    left: ${({ left }) => (left ? `${left}px` : '405px')};
    width: ${({ width }) => (width ? `${width} !important` : '300px')};
    max-width: ${({ width }) => (width ? `${width} !important` : '300px')};
    height: ${({ height }) => (height ? height : 'calc(100vh - 70px)')};
    background-color: rgba(0, 0, 0, 0.85);
    backdrop-filter: blur(3px);
    color: white;
    z-index: 500;
    transition: opacity 0.3s ease 0.1s, visibility 0.3s ease 0.4s;
    display: flex;
    flex-direction: column;
    border-left: none;
    padding: 0px;
    opacity: ${({ expanded }) => (expanded ? 1 : 0)};
    animation: ${({ expanded }) => (expanded ? expand : collapse)} 0.3s ease forwards;
`;

const SideDrawerFadeInContent = styled.div<{ expanded: boolean }>`
    display: flex;
    flex-direction: column;
    flex: 1;
    opacity: ${({ expanded }) => (expanded ? 1 : 0)};
    transition: opacity 0.4s ease;
    animation: ${({ expanded }) => (expanded ? 'fadeIn' : 'fadeOut')} 0.4s ease;
    margin: 0px 5px;

    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        75% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    @keyframes fadeOut {
        0% {
            opacity: 1;
        }
        75% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }
`;

const CloseIcon = styled.i<{ expanded: boolean }>`
    padding: 8px;
    font-size: 18px;
    color: white;
    cursor: pointer;
    float: right;
    opacity: ${({ expanded }) => (expanded ? 1 : 0)};
    transition: opacity 0.5s ease;
    animation: ${({ expanded }) => (expanded ? 'fadeCloseIconIn' : 'fadeCloseIconOut')} 0.5s ease;
    align-self: flex-end;
    z-index: 99999;

    @keyframes fadeCloseIconIn {
        0% {
            opacity: 0;
        }
        75% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    @keyframes fadeCloseIconOut {
        0% {
            opacity: 1;
        }
        75% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }
`;
