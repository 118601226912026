import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { StoaryResponse, UpdateStoaryMetaRequest } from '../../../api/stoaryModel';

import UriHelper from '../../../lib/uri-helper';
import Analytics from '../../../lib/user-analytics';
import { PulseLoader } from '../../Shared/pulse-loader';
import NoImageryModal from '../Imagery/Shared/no-imagery-modal';
import NavigationFilterInput from '../Shared/navigation-filter-input';
import NavigationPanel from '../Shared/navigation-panel';
import profileIconUrl from '../profile-icon-url';

import { DrawStoarySort, DrawStoarySortOptions } from './draw-stoary-dashboard-options';

import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
    actionFetchSharedDrawProjects,
    actionFetchUserDrawProjects,
    actionUpdateDrawProject,
} from '../../../store/Map/DrawProject/actions';
import {
    selectDrawProjects,
    selectDrawProjectsIsFetched,
    selectIsUpdatingDrawProject,
    selectSharedDrawProjects,
    selectSharedDrawProjectsIsFetched,
} from '../../../store/Map/DrawProject/selectors';
import DrawProjectsEditDialog from './draw-projects-edit-dialog';
import DrawStoaryList from './draw-stoary-dashboard-list';

const MyDrawStoaryDashboard = () => {
    const dispatch = useDispatch();
    const isUpdatingDrawProject = useSelector(selectIsUpdatingDrawProject);

    const drawProjects = useSelector(selectDrawProjects);
    const isFetchedDrawProjects = useSelector(selectDrawProjectsIsFetched);

    const sharedDrawProjects = useSelector(selectSharedDrawProjects);
    const isFetchedSharedDrawProjects = useSelector(selectSharedDrawProjectsIsFetched);

    const [filterValue, setFilterValue] = useState<string>('');
    const [sortBy, setSortBy] = useState<string | undefined>(undefined);
    const [showEditModal, setShowEditModal] = useState(false);
    const [editingProjectId, setEditingProjectId] = useState<number | null>(null);
    useEffect(() => {
        if (!isFetchedDrawProjects) {
            dispatch(actionFetchUserDrawProjects());
        }
        if (isFetchedDrawProjects) {
            if (!isFetchedSharedDrawProjects) {
                dispatch(actionFetchSharedDrawProjects());
            }
        }
    }, [dispatch, isFetchedDrawProjects, isFetchedSharedDrawProjects]);

    const handleClearFilter = () => {
        setFilterValue('');
    };

    const onEditProject = (projectId: number) => {
        setEditingProjectId(projectId);
        setShowEditModal(true);
    };

    const onUpdateProject = (project: UpdateStoaryMetaRequest) => {
        if (!editingProjectId || !project) {
            toast.error('Error updating project, Please try again');
            return;
        }
        dispatch(actionUpdateDrawProject(editingProjectId, project));
        toast.dark('Stoary updated successfully!');
        Analytics.Event('Draw Tools - Dashboard', 'Updated draw project', editingProjectId);
        setEditingProjectId(null);
        setShowEditModal(false);
    };

    let filteredList = [...drawProjects, ...sharedDrawProjects];
    if (filteredList) {
        if (filterValue.length > 0) {
            filteredList = filteredList.filter(
                (drawProject: StoaryResponse) =>
                    (drawProject.title || '').toLowerCase().includes(filterValue) ||
                    (drawProject.description || '').toLowerCase().includes(filterValue) ||
                    (drawProject.ownerName || '').toLowerCase().includes(filterValue)
            );
        }
        if (sortBy === DrawStoarySort.Title) {
            filteredList = filteredList.sort((a: StoaryResponse, b: StoaryResponse) =>
                (a.title || '').localeCompare(b.title || '')
            );
        } else if (sortBy === DrawStoarySort.LastPosted) {
            filteredList = filteredList.sort((a: StoaryResponse, b: StoaryResponse) =>
                a?.createdAt > b?.createdAt ? 1 : -1
            ); // descending
        } else if (sortBy === DrawStoarySort.Newest) {
            filteredList = filteredList.sort((a: StoaryResponse, b: StoaryResponse) =>
                a?.createdAt < b?.createdAt ? 1 : -1
            );
        } else if (sortBy === DrawStoarySort.Shared) {
            filteredList = filteredList.sort((a: StoaryResponse, b: StoaryResponse) =>
                a?.sharedAt && b?.sharedAt ? (a?.sharedAt < b?.sharedAt ? 1 : -1) : -1
            );
        } else {
            filteredList = filteredList.sort((a: StoaryResponse, b: StoaryResponse) =>
                (a.updatedAt ?? a.createdAt) > (b.updatedAt ?? b.createdAt) ? -1 : 1
            ); //default order by updatedAt desc
        }
    }

    return (
        <React.Fragment>
            <NavigationPanel label={'My Stoaries'} />
            <NavigationFilterInput
                placeholderText={'Filter by title or author'}
                filterValue={filterValue}
                setFilterValue={setFilterValue}
                setSortValue={setSortBy}
                handleClearFilter={handleClearFilter}
                filterComponent="Draw Tools - Dashboard"
                dataTestId="dashboard-mydrawproject-filter"
                sortOptions={DrawStoarySortOptions.options}
                title={DrawStoarySortOptions.title}
            />
            {!isFetchedDrawProjects ? (
                <DrawStoaryLoader>
                    <PulseLoader />
                </DrawStoaryLoader>
            ) : isFetchedDrawProjects && drawProjects.length === 0 && sharedDrawProjects.length === 0 ? (
                <NoImageryModal
                    icon="/assets/stoaries-icons/draw-project-yellow-cropped.svg"
                    title={'It looks like your Stoaries are empty'}
                    mobileText={''}
                    button={() => {
                        UriHelper.navigateToPath('/draw/new');
                        Analytics.Event('Draw Tools - Dashboard', 'Clicked create first Stoary');
                    }}
                    buttonText={'Create your first Stoary'}
                />
            ) : (
                <DrawStoaryList
                    dataTestId="dashboard-mydrawproject-list"
                    drawProjects={filteredList}
                    onEditProject={onEditProject}
                    onInvalidateProjects={() => {
                        dispatch(actionFetchUserDrawProjects());
                    }}
                />
            )}
            {editingProjectId && (
                <DrawProjectsEditDialog
                    key={editingProjectId}
                    isOpen={showEditModal}
                    project={drawProjects.find((project) => project.id === editingProjectId)}
                    onSubmit={onUpdateProject}
                    toggle={() => setShowEditModal(false)}
                    isUpdating={isUpdatingDrawProject}
                />
            )}
        </React.Fragment>
    );
};

export default MyDrawStoaryDashboard;

const DrawStoaryLoader = styled.div`
    text-align: center;
    height: 60vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
