import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { ListingDTO } from '../../../../api/model';
import SoarModal from '../../../Shared/soar-modal';
import { actionShowLoginDialog } from '../../../../store/App/actions';
import { selectLoggedIn } from '../../../../store/Account/selectors';
import EmbeddedMapDialogBody from './embedded-map-dialog-body';
import { selectActiveAnnotationString } from '../../../../store/SocialMapping/selectors';
import ApiComments from '../../../../api/api-comments';
import ApiEmbedMap from '../../../../api/api-embed-map';
import Analytics from '../../../../lib/user-analytics';
import { PulseLoader } from '../../../Shared/pulse-loader';
import { toast } from 'react-toastify';
import { ShareLinkIcon } from '../ActiveMap/Sharing/share-link';

interface EmbeddedMapDialogProps {
    tileLayer: ListingDTO;
}

const EmbeddedMapDialog = ({ tileLayer }: EmbeddedMapDialogProps) => {
    const [openEmbed, setOpenEmbedModal] = useState<boolean>(false);
    const [embedKey, setEmbedKey] = useState<number | undefined>(undefined);

    const selectedAnnotationString = useSelector(selectActiveAnnotationString);

    const isLoggedIn = useSelector(selectLoggedIn);
    const dispatch = useDispatch();

    const handleAnnotationKeyCreation = async (annotations: string) => {
        return await ApiComments.createComment({
            annotations: annotations,
            text: [''],
        })
            .then((res) => {
                return res.id as string;
            })
            .catch((error) => {
                const errorMessage = `Embedded Map - Failed to generate annotations for map id ${
                    tileLayer.id
                }: ${error.toString()}`;
                handleError(error, errorMessage);
            });
    };

    const handleSanitizingAnnotationKey = async (): Promise<string | undefined> => {
        if (selectedAnnotationString) {
            const annotations = JSON.parse(selectedAnnotationString);

            // Sanitize selectedAnnotationString
            if (annotations['8']) {
                delete annotations['8'];
            }

            // mapBounds is removed, takes in the drawer and presents an undesirable flyto
            if (annotations['mapBounds']) {
                delete annotations.mapBounds;
            }

            const annotationLength = Object.keys(annotations).length;
            if (annotationLength === 0 || (annotations['zoomLevel'] && annotationLength === 1)) {
                return;
            }
            const id = await handleAnnotationKeyCreation(JSON.stringify(annotations));
            return id as string | undefined;
        } else {
            return;
        }
    };

    const handleCreateEmbeddedMapKey = async () => {
        ApiEmbedMap.createEmbedKey({
            mapId: tileLayer.id,
            annotations: await handleSanitizingAnnotationKey(),
        })
            .then((key) => {
                Analytics.Event('Embedded Map', `Key generated for map id ${tileLayer.id}`, `Key: ${key}`);
                setEmbedKey(key);
            })
            .catch((error) => {
                const errorMessage = `Embedded Map - Failed to generate key for map id ${
                    tileLayer.id
                }: ${error.toString()}`;
                handleError(error, errorMessage);
            });
    };

    const handleClickedEmbedded = () => {
        if (!isLoggedIn) {
            Analytics.Event('Embedded Map', 'Clicked embed icon - Logged in', 'False');
            dispatch(actionShowLoginDialog(true));
        } else {
            Analytics.Event('Embedded Map', 'Clicked embed icon - Logged in', 'True');
            Analytics.Event(
                'Embedded Map',
                `Opened Embed tool for ${tileLayer.id} `,
                `Annotations ${selectedAnnotationString ? true : false}`
            );
            handleCreateEmbeddedMapKey();
            setOpenEmbedModal(!openEmbed);
        }
    };

    const handleToggleEmbedded = () => {
        Analytics.Event(
            'Embedded Map',
            `Closed Embed tool for ${tileLayer.id} `,
            `Annotations ${selectedAnnotationString ? true : false}`
        );
        setOpenEmbedModal(!openEmbed);
        setEmbedKey(undefined);
    };

    const handleError = (error: string, errorMessage?: string) => {
        setOpenEmbedModal(!openEmbed);
        console.error(error);
        toast.error(errorMessage);
    };

    return (
        <React.Fragment>
            <EmbeddedIcon
                title="Embed Map"
                className="fa fa-code embed-map-icon"
                onClick={handleClickedEmbedded}
                data-testid="map-embed"
            />
            <EmbeddedSoarModal
                isOpen={openEmbed}
                toggle={handleToggleEmbedded}
                title={'Soar Embedded Map'}
                width="600px"
            >
                {embedKey && <EmbeddedMapDialogBody tileLayer={tileLayer} embedKey={embedKey} />}
                {!embedKey && (
                    <React.Fragment>
                        <EmbeddedMapText>Generating Code</EmbeddedMapText>
                        <PulseLoader iconSize="2rem" iconMargin="10px" />
                    </React.Fragment>
                )}
            </EmbeddedSoarModal>
        </React.Fragment>
    );
};

export default EmbeddedMapDialog;

const EmbeddedSoarModal = styled(SoarModal)`
    padding-bottom: 20px;
`;

const EmbeddedIcon = styled(ShareLinkIcon)`
    margin-top: 1px;
    pointer-events: all;

    &:hover {
        color: white;
    }
`;

const EmbeddedMapText = styled.h3`
    margin-top: 20px;
    color: #eed926;
`;
