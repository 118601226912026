import React, { useState } from 'react';
import styled from 'styled-components';
import ProjectPermissionsUserTable from './project-permissions-user-table';

interface ProjectPermissionsUserEmailListProps {
    invitedUserEmails: string[];
    onRemoveUser: (email: string) => void;
}

const ProjectPermissionsUserEmailList = (props: ProjectPermissionsUserEmailListProps) => {
    const [showManageUsers, setShowManageUsers] = useState<boolean>(false);

    if (showManageUsers) {
        return (
            <ProjectPermissionsUserTable
                invitedUserEmails={props.invitedUserEmails}
                onRemoveUser={(email) => {
                    props.onRemoveUser(email);
                }}
            />
        );
    }

    if (props.invitedUserEmails.length === 0) {
        return <React.Fragment />;
    }

    if (props.invitedUserEmails.length > 5) {
        return (
            <UserEmailList>
                {props.invitedUserEmails.slice(0, 5).join(', ')} and {props.invitedUserEmails.length - 5} more users can
                view this Stoary
                <ManageButton onClick={() => setShowManageUsers(true)}>(Manage users)</ManageButton>
            </UserEmailList>
        );
    }

    return (
        <UserEmailList>
            {props.invitedUserEmails.join(', ')} can view this Stoary
            <ManageButton onClick={() => setShowManageUsers(true)}>(Manage users)</ManageButton>
        </UserEmailList>
    );
};

export default ProjectPermissionsUserEmailList;

const UserEmailList = styled.p`
    color: lightgray;
    margin: 4px 0 8px 12px;
    font-size: 0.9rem;
`;

const ManageButton = styled.button`
    display: inline-block;
    margin: 0;
    padding: 0 4px;
    outline: none !important;
    font-size: 0.9rem;
    user-select: none;
    background-color: transparent !important;
    color: #eed926;
    -webkit-text-fill-color: #eed926;
    border: none;

    :active,
    :focus {
        outline: none;
    }
`;
