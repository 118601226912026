import React from 'react';
import styled from 'styled-components';
import SoarHelper from '../../../lib/soar-helper';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import UriHelper from '../../../lib/uri-helper';
import { selectMyProfile } from '../../../store/Account/selectors';
import Analytics from '../../../lib/user-analytics';
import UserHelper from '../../../lib/user-helper';

interface SideDrawerIconsProps {
    expanded: boolean;
    setExpanded: (expanded: boolean) => void;
}

const SideDrawerIcons = ({ expanded, setExpanded }: SideDrawerIconsProps) => {
    const userProfile = useSelector(selectMyProfile);

    const url = useLocation();

    const toggleDrawer = (route: string, name?: string) => {
        Analytics.Event('Side Drawer', `Clicked ${name || route}`);
        if (!url.pathname.includes(route)) {
            UriHelper.navigateToPath(route);
            setExpanded(true);
        } else {
            setExpanded(!expanded);
        }
    };

    interface NavIconProps {
        name?: string;
        title: string;
        src: string;
        route: string;
    }

    const NavIcon = ({ name, title, src, route }: NavIconProps) => {
        return (
            <NavIconButtonContainer
                className={url.pathname.includes(`/${route}`) ? 'active-draw-icon' : ''}
                onClick={() => toggleDrawer(`/${route}`, name)}
            >
                <NavIconButton
                    title={title}
                    src={src}
                    onClick={() => toggleDrawer(`/${route}`, name)}
                    className={url.pathname.includes(`/${route}`) ? 'active-draw-icon' : ''}
                />
                <NavIconButtonText>{name}</NavIconButtonText>
            </NavIconButtonContainer>
        );
    };

    return (
        <NavIconContainer>
            <NavIcon name="MAPS" title="Search Maps" src="/assets/side-drawer/map_yellow.svg" route="maps" />
            <NavIcon
                name="DISCOVER"
                title="Featured Maps"
                src="/assets/side-drawer/discover_yellow.svg"
                route="featured"
            />
            <NavIcon
                name="SATELLITES"
                title="Search Satellites"
                src="/assets/side-drawer/satellite_yellow.svg"
                route="satellites"
            />
            {/* <NavIcon name="STOARIES" title="Search Stoaries" src="/assets/dashboard/draw_yellow.svg" route="draw" /> */}

            {userProfile && SoarHelper.isSoar() ? (
                <React.Fragment>
                    <NavIcon
                        name="BOOKMARKS"
                        title="My Bookmarks"
                        src="/assets/side-drawer/bookmark_yellow.svg"
                        route="my-bookmarks"
                    />
                    {/* <NavIcon
                        name="MY MAPS"
                        title="My Maps"
                        src="/assets/dashboard/my-maps_yellow.svg"
                        route="my-maps"
                    /> */}
                </React.Fragment>
            ) : null}
            {UserHelper.hasWritePermissionsForSubdomain(userProfile) ? (
                <NavIcon
                    name="UPLOAD"
                    title="Add your maps"
                    src="/assets/side-drawer/upload_yellow.svg"
                    route="upload"
                />
            ) : null}
        </NavIconContainer>
    );
};

export default SideDrawerIcons;

const NavIconContainer = styled.div`
    width: 76px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const NavIconButtonContainer = styled.div`
    cursor: pointer;
    border: 1px solid transparent;
    text-align: center;
    width: 68px;
    height: 68px;
    padding-top: 5px;

    &:hover {
        color: #ddd;
    }

    :first-child {
        margin-top: 5px;
    }

    &.active-draw-icon {
        background: rgba(255, 255, 255, 0.05);
        border-radius: 6px;
        border: 1px solid rgb(81, 81, 81);
    }
`;

const NavIconButton = styled.img`
    user-select: none;

    &.active-draw-icon {
        scale: 0.95;
    }
`;

const NavIconButtonText = styled.p`
    color: white;
    font-size: 11px;
    margin-top: 4px;
    user-select: none;
`;
